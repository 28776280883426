import { FC, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { OptionDropdownWithImage } from "components/ui/optionDropdown/optionDropdown";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/icon-arrow-down.svg";
import { Button } from 'components/ui/button/button';
import { Field } from "components/ui/field/field";
import cn from "clsx";
import styles from "./referenceDocumentForm.module.scss";
import { DocProject, ReferenceDocumentType, ReferenceDocumentStatus, OrgAuthor, StudyAreaBoundary } from "actions/lib/types";
import { useAdminState } from 'context/admin';
import maplibregl from 'maplibre-gl';

interface GeoJSON {
  type: string;
  [key: string]: any;
}

interface Polygon {
  type: "Polygon";
  coordinates: number[][][];
}
export interface ReferenceDocumentFormFields {
  projectName: string;
}

export const ReferenceDocumentFormComponent: FC = () => {

  const adminState = useAdminState();

  const {
    docProjectList,
    referenceDocumentTypeList,
    referenceDocumentStatusList,
    orgAuthorList,
    studyAreaBoundaryList,
    isLoading,
  } = adminState;
  
  const [geojsonData, setGeojsonData] = useState<GeoJSON | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, formState: { errors }, watch } = useForm<ReferenceDocumentFormFields>();

  const docProjects = docProjectList.map((docProject: DocProject) => { return { value: docProject.title, label: docProject.title, id: docProject.id }; });
  const docTypes = referenceDocumentTypeList.map((referenceDocumentType: ReferenceDocumentType) => { return { value: referenceDocumentType.name, label: referenceDocumentType.name, id: referenceDocumentType.id } });
  const docStatuses = referenceDocumentStatusList.map((referenceDocumentStatus: ReferenceDocumentStatus) => { return { value: referenceDocumentStatus.name, label: referenceDocumentStatus.name, id: referenceDocumentStatus.id } });
  const organizations = orgAuthorList.map((orgAuthor: OrgAuthor) => { return { value: orgAuthor.name, label: orgAuthor.name, id: orgAuthor.id } });
  const boundaries = studyAreaBoundaryList.map((studyAreaBoundary: StudyAreaBoundary) => { return { value: studyAreaBoundary.description, label: studyAreaBoundary.description, id: studyAreaBoundary.id, geom: studyAreaBoundary.geom, extent: studyAreaBoundary.extent, base64: studyAreaBoundary.base64 } });
  const map = useRef<maplibregl.Map | null>(null);

  const [boundaryList, setBoundaryList] = useState(boundaries);

  useEffect(() => {
    map.current = new maplibregl.Map({
      container: 'map',
      style: 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
      zoom: 10,
      center: [-79.4731517246409, 43.72872024356051],
      attributionControl: false
    });

    map.current.on('load', () => {
      if (!map.current) return;
      map.current.addSource('boundary', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Polygon',
            'coordinates': []
          }
        }
      });
      map.current.addLayer({
        'id': 'boundary',
        'type': 'fill',
        'source': 'boundary',
        'layout': {},
        'paint': {
          'fill-color': '#088',
          'fill-opacity': 0.5
        }
      });
      map.current.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'boundary',
        'layout': {},
        'paint': {
          'line-color': '#088',
          'line-width': 3
        }
      });
    });
  }, []);

  const handleOptionChange = (event: any) => {
    console.log("change option = ", event.value);
  };

  const handleBoundaryChange = (event: any) => {
    if (map.current) {
      map.current.fitBounds(event.extent, {
        padding: { top: 20, bottom: 20, left: 25, right: 25 },
        maxZoom: 14,
      });

      const boundary_source = map.current.getSource('boundary') as maplibregl.GeoJSONSource;
      boundary_source ? boundary_source.setData({
        "type": "Feature",
        "properties": {},
        "geometry": {
          "type": 'Polygon',
          'coordinates': event.geom
        }
      }) : null;
    }
  };

  const onDateChange = (event: any) => {
    console.log("change date = ", event);
  };

  const onFieldChange = (event: any) => {
    console.log("change field = ", event);
  };

  const onSubmit = async (values: ReferenceDocumentFormFields) => {
    //  Do nothing
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.refDocForm}>
      <div className={styles.sideBySide}>
        <div className={cn(styles.section, styles.refDocSection)}>
          <label>ADD A NEW DOC</label>
          <hr className={styles.solid}></hr>

          <Field
            labelName={'Project Name'}
            fieldName={'projectName'}
            fieldType={'dropDown'}
            options={docProjects}
            handleOptionChange={handleOptionChange}
          />

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Link Doc'}
              fieldName={'linkDoc'}
              fieldType={'checkBox'}
              onFieldChange={onFieldChange}
            />
            <Field
              labelName={'Chose Previous Doc Edition from District Database'}
              fieldName={'previousDocEdition'}
              fieldType={'dropDown'}
              options={[]}
              handleOptionChange={handleOptionChange}
            />
          </div>

          <Field
            labelName={'Doc Title'}
            fieldName={'docTitle'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Doc Type'}
            fieldName={'docType'}
            fieldType={'dropDown'}
            options={docTypes}
            handleOptionChange={handleOptionChange}
            customClassName={styles.halfLength}
          />

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Prepared By'}
              fieldName={'preparedBy'}
              fieldType={'dropDown'}
              options={organizations}
              handleOptionChange={handleOptionChange}
              fieldClassName={styles.leftField}
            />
            <Field
              labelName={'Prepared For'}
              fieldName={'preparedFor'}
              fieldType={'dropDown'}
              options={organizations}
              handleOptionChange={handleOptionChange}
            />
          </div>

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Date Issued'}
              fieldName={'dateIssued'}
              fieldType={'dateTime'}
              onDateChange={onDateChange}
              fieldClassName={styles.leftField}
            />
            <Field
              labelName={'Doc Status'}
              fieldName={'docStatus'}
              fieldType={'dropDown'}
              options={docStatuses}
              handleOptionChange={handleOptionChange}
            />
          </div>

          <Field
            labelName={'Doc URL (Link to PDF)'}
            fieldName={'docURL'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Website URL'}
            fieldName={'websiteURL'}
            fieldType={'textBox'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Language'}
            fieldName={'language'}
            fieldType={'dropDown'}
            options={[]}
            handleOptionChange={handleOptionChange}
            customClassName={styles.halfLength}
          />

          <Field
            labelName={'Boundary Page Reference'}
            fieldName={'boundaryPageReference'}
            fieldType={'dropDown'}
            options={[]}
            handleOptionChange={handleOptionChange}
            customClassName={styles.halfLength}
          />

          <Field
            labelName={'Doc Description'}
            fieldName={'docDescription'}
            fieldType={'textArea'}
            register={register}
            fieldClassName={styles.textField}
          />

          <Field
            labelName={'Doc Tags'}
            fieldName={'docTags'}
            fieldType={'textArea'}
            register={register}
            fieldClassName={styles.textField}
          />

          <label>LOCATION</label>
          <hr className={styles.solid}></hr>

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Municiplaity / County'}
              fieldName={'municipality'}
              fieldType={'dropDown'}
              options={[]}
              handleOptionChange={handleOptionChange}
              fieldClassName={styles.leftField}
            />
            <Field
              labelName={'Region / District'}
              fieldName={'region'}
              fieldType={'dropDown'}
              options={[]}
              handleOptionChange={handleOptionChange}
            />
          </div>

          <div className={styles.fieldGroup}>
            <Field
              labelName={'Province / State'}
              fieldName={'province'}
              fieldType={'dropDown'}
              options={[]}
              handleOptionChange={handleOptionChange}
              fieldClassName={styles.leftField}
            />
            <Field
              labelName={'Country'}
              fieldName={'country'}
              fieldType={'dropDown'}
              options={[]}
              handleOptionChange={handleOptionChange}
            />
          </div>

          <Button className={styles.submitButton} type="submit">
            Save
          </Button>
        </div>
        <div className={styles.section}>
          <label>STUDY AREA BOUNDARY</label>
          <hr className={styles.solid}></hr>
          <div className={styles.mapContainer}>
            <div id="map" className={styles.map}></div>
          </div>
          <div className={styles.field} >
            <label>Select Doc Boundary from District Database</label>
            <OptionDropdownWithImage label={''} options={boundaries} handleOptionChange={handleBoundaryChange} className={styles.halfLength} dropdownIcon={<ArrowDownIcon />} />
          </div>
        </div>
      </div>
    </form>
  );
};

export const ReferenceDocumentForm = withRouter(ReferenceDocumentFormComponent);
