import React, { ReactNode, useEffect, useState } from "react";
import styles from "./tableWrapper.module.scss";
import { User, UserGroup } from "actions/lib/types";
import { DataList } from "components/ui/dataList/dataList";
import { DataTable } from "components/ui/dataTable/dataTable";

export enum TableType {
  Table,
  List,
}

interface TableWrapperProps<T = any> {
  tableData: T[];
  tableHeaders?: Array<{ header: ReactNode }>;
  tableType: TableType;
  onRowClick?: (data: T) => void;
}

const _renderTableType = (
  tableData: any,
  tableType: any,
  tableHeaders?: Array<{ header: ReactNode }>,
  onRowClick?: (data: any) => void
) => {
  switch (tableType) {
    case TableType.Table:
      return <DataTable
        tableData={tableData}
        tableHeaders={tableHeaders}
        onRowClick={onRowClick}
      />;
    case TableType.List:
      return <DataList tableData={tableData} />;
  }
};

export const TableWrapper = <T, >({
  tableData,
  tableType,
  tableHeaders,
  onRowClick
}: TableWrapperProps<T>) => {
  return (
    <div className={styles.tableContainer}>
      {_renderTableType(tableData, tableType, tableHeaders, onRowClick)}
    </div>
  );
};
