import { Button, ButtonStyle } from "components/ui/button/button";
import styles from "./dataTable.module.scss";
import cn from "clsx";
import { ReactComponent as CheckIcon } from "assets/images/icons/icon-check.svg";
import { UserGroup } from "actions/lib/types";
import BodyText from "components/ui/typograhpy/bodyText/bodyText";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { ReactNode } from "react";

interface DataTableProps {
  tableData: any[];
  tableHeaders?: { header: ReactNode }[];
  onRowClick?: (data: any) => void;
}

export const DataTable = ({ tableData, tableHeaders, onRowClick }: DataTableProps) => {
  return (
    <table className={styles.table}>
      {tableHeaders?.map((headerItem: { header: ReactNode }) => {
        return headerItem.header;
      })}
      <tbody>
        {tableData?.map((rowItem: any, index: number) => {
          return (
            <tr 
              key={index}
              onClick={() => onRowClick?.(rowItem)}
              className={styles.tableRow}
            >
              {Object.keys(rowItem)
                .filter(key => key !== 'id')
                .map((key) => (
                  <td key={key}>
                    <div className={styles.textCenter}>
                      <BodyText>{rowItem[key]}</BodyText>
                    </div>
                  </td>
                ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
