import { creatContextStore } from './base';
import { DocProject, ReferenceDocumentType, ReferenceDocumentStatus, OrgAuthor, StudyAreaBoundary } from 'actions/lib//types';

interface AdminState {
  docProjectList: DocProject[];
  referenceDocumentTypeList: ReferenceDocumentType[];
  referenceDocumentStatusList: ReferenceDocumentStatus[];
  orgAuthorList: OrgAuthor[];
  studyAreaBoundaryList: StudyAreaBoundary[];
  isLoading: boolean;
  error: string | null;
}

const initialState: AdminState = {
  docProjectList: [],
  referenceDocumentTypeList: [],
  referenceDocumentStatusList: [],
  orgAuthorList: [],
  studyAreaBoundaryList: [],
  isLoading: false,
  error: null
};

const [
  AdminContextProvider,
  useAdminState,
  useAdminSetState,
  useAdminUpdate
] = creatContextStore<AdminState>(initialState);

export {
  AdminContextProvider,
  useAdminState,
  useAdminSetState,
  useAdminUpdate
};