import { useEffect } from "react";
import { fetchDocProjects, fetchReferenceDocumentTypes, fetchReferenceDocumentStatuses, fetchOrgAuthors, fetchStudyAreaBoundaries } from "actions/adminActions";
import { useAdminSetState, useAdminState } from 'context/admin';

const AdminDataLoader = () => {
  const setAdminState = useAdminSetState();

  const fetchStudyData = async (): Promise<void> => {
    setAdminState((prev) => ({ ...prev, isLoading: true, error: null }));
    
    try {
      const [docProjects, referenceDocumentTypes, referenceDocumentStatuses, orgAuthors, studyAreaBoundaries] = await Promise.all([
        fetchDocProjects(),
        fetchReferenceDocumentTypes(),
        fetchReferenceDocumentStatuses(),
        fetchOrgAuthors(),
        fetchStudyAreaBoundaries()
      ]);

      setAdminState(prev => ({
        ...prev,
        docProjectList: docProjects,
        referenceDocumentTypeList: referenceDocumentTypes,
        referenceDocumentStatusList: referenceDocumentStatuses,
        orgAuthorList: orgAuthors,
        studyAreaBoundaryList: studyAreaBoundaries,
        isLoading: false
      }));
    } catch (err) {
      setAdminState(prev => ({
        ...prev,
        error: 'Failed to fetch study data',
        isLoading: false
      }));
      console.error('Error fetching study data:', err);
    }
  };

  useEffect(() => {
    fetchStudyData();
  }, []);

  return null;
};

export default AdminDataLoader;