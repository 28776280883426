import React, { ReactNode, useEffect, useState } from "react";
import { Button, ButtonStyle, LinkButton } from "components/ui/button/button";
import { RoutePaths } from "app/routing/routing";
import styles from "./header.module.scss";
import Title from "components/ui/typograhpy/title/title";
import { FilterSort } from "../filterSort/filterSort";
import { DropdownOption } from "components/ui/optionDropdown/optionDropdown";
import TextField from "components/ui/textField/textField";
import {
  RegisterOptions,
  UseFormRegisterReturn,
  useForm,
} from "react-hook-form";
import { ReactComponent as SearchIcon } from "assets/images/icons/icon-search.svg";
import cn from "clsx";

interface HeaderProps {
  headerName: string;
  filterOptions?: Array<DropdownOption>;
  sortOptions?: Array<DropdownOption>;
  ctaString?: string;
  handleCTA?: () => void;
  hasSearch?: boolean;
  children?: ReactNode;
  childrenClass?: string;
  filterSortSearchClass?: string;
  showBackButton?: boolean;
  onBackClick?: () => void;
}

export interface FilterSearchFormFields {
  searchString: string;
}

export const Header = ({
  headerName,
  filterOptions,
  sortOptions,
  ctaString,
  handleCTA,
  hasSearch,
  children,
  childrenClass,
  filterSortSearchClass,
  showBackButton,
  onBackClick,
}: HeaderProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FilterSearchFormFields>();

  const handleFilterChange = (option: DropdownOption) => {
    console.log("Filter: ", option.value);
  };

  const handleSortChange = (option: DropdownOption) => {
    console.log("Sort:", option.value);
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerTitleCTA}>
        {showBackButton && (
          <Button 
            onClick={onBackClick} 
            style={ButtonStyle.None}
            className={styles.backButton}
          >
            {"<"}
          </Button>
        )}
        {showBackButton ? (
          <Title>{headerName}</Title>
        ) : (
          <LinkButton style={ButtonStyle.None} to={RoutePaths.Home}>
            <Title>{headerName}</Title>
          </LinkButton>
        )}
        {ctaString && <Button onClick={handleCTA}>{ctaString}</Button>}
      </div>
      <div className={cn(styles.filterSortSearch, filterSortSearchClass)}>
        <FilterSort
          handleFilterChange={handleFilterChange}
          handleSortChange={handleSortChange}
          filterOptions={filterOptions}
          sortOptions={sortOptions}
        />
        {hasSearch && <TextField
          name={"Search"}
          placeholder="Search"
          type={"text"}
          register={register}
          fieldIcon={<SearchIcon />}
        />}
      </div>
      <div className={childrenClass}>{children}</div>
    </div>
  );
};
