import { useState } from 'react';
import Layout from "components/layout/Layout";
import styles from "./organization.module.scss";
import { Header } from "components/shared/header/header";
import { OrganizationForm } from "components/organizationForm/organizationForm";
import { Tab } from "components/tab/tab";

import { useHistory } from "react-router";
import { TableType } from "components/shared/tableWrapper/tableWrapper";
import SmallText from "components/ui/typograhpy/smallText/smallText";
import { TableWrapper } from "components/shared/tableWrapper/tableWrapper";
import { AdminContextProvider } from 'context/admin';
import UseAdminHook from "../../adminHook";
import { useAdminState } from 'context/admin';
import UseOrganizationHook from "./organizationHook";
import { OrgAuthor } from 'actions/lib/types';

const OrganizationContent = () => {
  // Initialize admin hook to fetch data
  UseAdminHook();

  const { pageTabs, onNavTabClick, selectedNavTab } = UseOrganizationHook();
  const history = useHistory();
  const [selectedOrg, setSelectedOrg] = useState<any>(null);

  const adminState = useAdminState();
  const {
    orgAuthorList,
  } = adminState;

  const organizations = orgAuthorList.map((orgAuthor: OrgAuthor) => {
    return {
      id: orgAuthor.id,
      name: orgAuthor.name,
      address: orgAuthor.address,
      phone: orgAuthor.phone,
      email: orgAuthor.email
    }
  });

  const handleBackClick = () => {
    if (selectedOrg) {
      setSelectedOrg(null); // Go back to list view
    } else {
      history.goBack(); // Go back to previous page
    }
  };

  const tableHeaders = [
    {
      header:
        <th>
          <SmallText className={styles.textCenter}>Name</SmallText>
        </th>
    },
    {
      header:
        <th>
          <SmallText className={styles.textCenter}>Address</SmallText>
        </th>
    },
    {
      header:
        <th>
          <SmallText className={styles.textCenter}>Phone</SmallText>
        </th>
    },
    {
      header:
        <th>
          <SmallText className={styles.textCenter}>Email</SmallText>
        </th>
    },
  ];

  const handleOrgClick = (org: any) => {
    setSelectedOrg(org);

    console.log('Selected org:', org);
  };

  return (
    <div>
      <div className={styles.studyHeader}>
        <Header
          headerName={selectedOrg ? "Return" : "Organizations"}
          showBackButton={true}
          onBackClick={handleBackClick}
        />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.appContainer}>
          <>
            {!selectedOrg ? (
              <div className={styles.tableContainer}>
                <TableWrapper
                  tableType={TableType.Table}
                  tableHeaders={tableHeaders}
                  tableData={organizations}
                  onRowClick={handleOrgClick}
                />
              </div>
            ) : (
              <OrganizationForm selectedOrg={selectedOrg} />
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export const Organization = () => {
  return (
    <Layout>
      <AdminContextProvider>
        <OrganizationContent />
      </AdminContextProvider>
    </Layout>
  );
};
