import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "../config/config";
import { _mockDataForProjects } from "pages/admin/mockData/mockDataForProjects";
import { _mockDataForReferenceDocumentTypes } from "pages/admin/mockData/mockDataForReferenceDocumentTypes";
import { _mockDataForReferenceDocumentStatuses } from "pages/admin/mockData/mockDataForReferenceDocumentStatuses";
import { _mockDataForOrganizations } from "pages/admin/mockData/mockDataForOrganizations";
import { _mockDataForStudyAreaBoundaries } from "pages/admin/mockData/mockDataForStudyAreaBoundaries";

const fetchProjects = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForProjects;
  }
  catch {
    return [];
  }
};

const fetchDocProjects = async () => {
  try {
    const projects = await base.get(apiEndpoints.doc_projects, {
      headers: {
        'Authorization': `JWT ${localStorage.getItem(jwtData.authToken)}`
      }
    });
    return projects.data?.data;
  }
  catch {
    return [];
  }
};

const fetchReferenceDocumentTypes = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForReferenceDocumentTypes;
  }
  catch {
    return [];
  }
};

const fetchReferenceDocumentStatuses = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForReferenceDocumentStatuses;
  }
  catch {
    return [];
  }
};

const fetchOrgAuthors = async () => {
  try {
    const orgAuthors = await base.get(apiEndpoints.org_authors, {
      headers: {
        'Authorization': `JWT ${localStorage.getItem(jwtData.authToken)}`
      }
    });
    return orgAuthors.data?.data;
  }
  catch {
    return [];
  }
};

const fetchOrganizations = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForOrganizations;
  }
  catch {
    return [];
  }
};

const fetchStudyAreaBoundaries = async () => {
  try {
    // TODO: Replace mock data with API call
    return _mockDataForStudyAreaBoundaries;
  }
  catch {
    return [];
  }
};

const fetchGeocodingResults = async (searchQuery: string) => {
  try {
    const params = new URLSearchParams({
      q: searchQuery,
      limit: '10',
      lon: '-79.3832',
      lat: '43.6532',
      location_bias_scale: '0.1'
    });

    const response = await fetch(`https://photon.komoot.io/api/?${params.toString()}`);
    const data = await response.json();
    return data;
  }
  catch {
    return null;
  }
};

const fetchAddressDetails = async (osmId: string) => {
  try {
    const params = new URLSearchParams({
      osm_ids: osmId,
      format: 'json',
    });

    const response = await fetch(`https://nominatim.openstreetmap.org/lookup?${params.toString()}`, {
      headers: {
        'Accept': 'application/json',
        // It's good practice to include a User-Agent header when using Nominatim
        // 'User-Agent': 'YourAppName/1.0'
      }
    });
    const data = await response.json();
    return data;
  }
  catch {
    return null;
  }
};

export { fetchProjects, fetchDocProjects, fetchReferenceDocumentTypes, fetchReferenceDocumentStatuses, fetchOrgAuthors, fetchOrganizations, fetchStudyAreaBoundaries, fetchGeocodingResults, fetchAddressDetails };