import { useEffect, useState } from 'react';
import Layout from "components/layout/Layout";
import styles from "./study.module.scss";
import { Header } from "components/shared/header/header";
import { ReferenceDocumentForm } from "components/referenceDocumentForm/referenceDocumentForm";
import { StudyProjectForm } from "components/studyProjectForm/studyProjectForm";
import { Tab } from "components/tab/tab";
import UseStudyHook from "./studyHook";
import { AdminContextProvider } from 'context/admin';
import UseAdminHook from "../../adminHook";


const StudyContent = () => {
  // Initialize admin hook to fetch data
  UseAdminHook();

  const { pageTabs, onNavTabClick, selectedNavTab } = UseStudyHook();

  return (
    <div>
      <div className={styles.studyHeader}>
        <Header
          headerName={"Study Form"}
        />
      </div>
      <div className={styles.navigationContainer}>
        <Tab icon={null} tabLabel={pageTabs.docs} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.docs || selectedNavTab === ""} />
        <Tab icon={null} tabLabel={pageTabs.project} onClick={onNavTabClick} isSelected={selectedNavTab === pageTabs.project} />
      </div>
      <div className={styles.appContainer}>
        {(selectedNavTab === "" || selectedNavTab === pageTabs.docs) &&
          <ReferenceDocumentForm />
        }

        {selectedNavTab === pageTabs.project &&
          <StudyProjectForm />
        }
      </div>
    </div>
  );
};

export const Study = () => {
  return (
    <Layout>
      <AdminContextProvider>
        <StudyContent />
      </AdminContextProvider>
    </Layout>
  );
};
