import { FC, useEffect, useState, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from 'components/ui/button/button';
import { Field } from "components/ui/field/field";
import { debounce } from 'lodash';
import { fetchGeocodingResults, fetchAddressDetails } from 'actions/adminActions';
import styles from "./organizationForm.module.scss";

export interface OrganizationFormFields {
  organizationType: string;
  organizationName: string;
  formerName: string;
  phone: string;
  email: string;
  website: string;
  unitNumber: string;
  street: string;
  municipality: string;
  region: string;
  province: string;
  country: string;
  postalCode: string;
}

interface OrganizationFormProps extends RouteComponentProps {
  selectedOrg?: any; // Replace 'any' with your organization type
}

export const OrganizationFormComponent: FC<OrganizationFormProps> = ({ selectedOrg, history, location, match }) => {

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<OrganizationFormFields>();

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);

  const debouncedSearch = useRef(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length >= 3) {
        const results = await fetchGeocodingResults(searchTerm);
        setSearchResults(results?.features || []);
        setShowAutocomplete(true);
      } else {
        setSearchResults([]);
        setShowAutocomplete(false);
      }
    }, 300)
  ).current;

  const handleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target instanceof HTMLInputElement) {
      const value = event.target.value;
      setValue('organizationName', value);
      debouncedSearch(value);
    }
  };

  const handleAutocompleteSelect = async (feature: any) => {
    const properties = feature.properties;
    
    // Construct OSM ID from osm_type and osm_id
    const osmIdWithType = `${properties.osm_type.charAt(0).toUpperCase()}${properties.osm_id}`;
    
    try {
      const addressDetails = await fetchAddressDetails(osmIdWithType);
      
      if (addressDetails && addressDetails[0]) {
        const address = addressDetails[0].address;
        
        // Populate form fields with detailed address
        setValue('organizationName', properties.name || '');
        setValue('unitNumber', address.house_number || '');
        setValue('street', address.road || properties.street || '');
        setValue('municipality', address.city || address.town || address.village || properties.city || '');
        setValue('region', address.state_district || address.county || '');
        setValue('province', address.state || properties.state || '');
        setValue('country', address.country || properties.country || '');
        setValue('postalCode', address.postcode || properties.postcode || '');
      } else {
        // Fallback to basic properties if detailed lookup fails
        setValue('organizationName', properties.name || '');
        setValue('street', properties.street || '');
        setValue('municipality', properties.city || '');
        setValue('province', properties.state || '');
        setValue('country', properties.country || '');
        setValue('postalCode', properties.postcode || '');
      }
    } catch (error) {
      console.error('Error fetching address details:', error);
      // Fallback to basic properties if detailed lookup fails
      setValue('organizationName', properties.name || '');
      setValue('street', properties.street || '');
      setValue('municipality', properties.city || '');
      setValue('province', properties.state || '');
      setValue('country', properties.country || '');
      setValue('postalCode', properties.postcode || '');
    }
    
    setShowAutocomplete(false);
  };

  useEffect(() => {
    if (selectedOrg) {
      setValue('organizationName', selectedOrg.name);
      setValue('phone', selectedOrg.phone);
      setValue('email', selectedOrg.email);
    }
  }, [selectedOrg, setValue]);

  const handleOptionChange = (event: any) => {
    console.log("change option = ", event.value);
  };

  const onSubmit = async (values: OrganizationFormFields) => {
    //  Do nothing
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>


      <Field
        labelName={'Organization Type'}
        fieldName={'organizationType'}
        fieldType={'dropDown'}
        options={[]}
        handleOptionChange={handleOptionChange}
        customClassName={styles.halfLength}
      />

      <div className={styles.autocompleteContainer}>
        <Field
          labelName={'Organization Name'}
          fieldName={'organizationName'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.textField}
          onFieldChange={handleOrganizationNameChange}
        />
        
        {showAutocomplete && searchResults.length > 0 && (
          <div className={styles.autocompleteDropdown}>
            {searchResults.map((result, index) => (
              <div
                key={index}
                className={styles.autocompleteItem}
                onClick={() => handleAutocompleteSelect(result)}
              >
                {result.properties.name || result.properties.street}
                {result.properties.city && `, ${result.properties.city}`}
                {result.properties.country && `, ${result.properties.country}`}
              </div>
            ))}
          </div>
        )}
      </div>

      <Field
        labelName={'Former Name'}
        fieldName={'formerName'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <label>CONTACT INFORMATION</label>
      <hr className={styles.solid}></hr>

      <Field
        labelName={'Phone'}
        fieldName={'phone'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.halfLength}
      />

      <Field
        labelName={'Email'}
        fieldName={'email'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <Field
        labelName={'Website'}
        fieldName={'website'}
        fieldType={'textBox'}
        register={register}
        fieldClassName={styles.textField}
      />

      <label>LOCATION</label>
      <hr className={styles.solid}></hr>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Unit Number'}
          fieldName={'unitNumber'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Street'}
          fieldName={'street'}
          fieldType={'textBox'}
          register={register}
        />
      </div>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Municiplaity / County'}
          fieldName={'municipality'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Region / District'}
          fieldName={'region'}
          fieldType={'textBox'}
          register={register}
        />
      </div>

      <div className={styles.fieldGroup}>
        <Field
          labelName={'Province / State'}
          fieldName={'province'}
          fieldType={'textBox'}
          register={register}
          fieldClassName={styles.leftField}
        />
        <Field
          labelName={'Country'}
          fieldName={'country'}
          fieldType={'textBox'}
          register={register}
        />
      </div>

      <Field
        labelName={'Postal Code / ZIP'}
        fieldName={'postalCode'}
        fieldType={'textBox'}
        register={register}
        customClassName={styles.halfLength}
      />

      <Button className={styles.submitButton} type="submit">
        Save
      </Button>
    </form>
  );
};

export const OrganizationForm = withRouter(OrganizationFormComponent);
